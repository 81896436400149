import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, googleProvider } from '../services/firebase';

export const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(result => {
      setCurrentUser(result);
      setIsAuthLoading(false);
    });
    return unsubscribe;
  }, []);

  function signInWithGoogle() {
    return auth.signInWithPopup(googleProvider);
  }

  function signIn(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function signOut() {
    return auth.signOut();
  }

  // used by API auth
  function getToken(forceRefresh = false) {
    return auth.currentUser.getIdToken(forceRefresh);
  }

  const value = {
    currentUser,
    isAuthLoading,
    signInWithGoogle,
    signIn,
    signOut,
    getToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
