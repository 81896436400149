import axios from 'axios';

export const getMe = token => {
  const url = `${process.env.REACT_APP_SERVER_URL}/api/me/`;
  return axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
};

export const getFinancialOverview = (token, month = null) => {
  const selectedMonth = month ? month : new Date().getMonth() + 1;
  const url = `${process.env.REACT_APP_SERVER_URL}/api/overview/?month=${selectedMonth}`;
  return axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
};

export const addPushToken = (token, pushToken) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/api/notification/token`;
  return axios.post(url, { pushToken }, {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
}

export const getNotifications = (token) => {
  const url = `${process.env.REACT_APP_SERVER_URL}/api/notification/get`;
  return axios.get(url,{
    headers:{
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
}
