import { Box, Text, VStack } from '@chakra-ui/layout';
import { Button, Flex, Spacer } from '@chakra-ui/react';

export default function Welcome({ currentUser, children }) {
  return (
    <Box>
      <Flex>
        <Spacer />
        <Button
          mt="6"
          mr="4"
          size="sm"
          color="red"
          borderRadius="lg"
          variant="outline"
        >
          Log Out
        </Button>
      </Flex>

      <VStack>
        <Box>
          <Text
            fontSize="30px"
            fontWeight="500"
            color="#41474A"
            textAlign="center"
          >
            Hello, {currentUser.displayName}
          </Text>
        </Box>
        {children}
      </VStack>
    </Box>
  );
}
