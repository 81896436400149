import { Box, Text } from '@chakra-ui/layout';
import { Button, Icon } from '@chakra-ui/react';
import GoogleIcon from './GoogleIcon';

export default function GmailAuthoNeeded() {
  return (
    <Box>
      <Text fontSize="30px" fontWeight="200" color="#41474A" textAlign="center">
        welcome to Voosh
      </Text>
      <Text fontSize="16px" fontWeight="400" color="#C0C2D1" textAlign="center">
        Please authorize your Gmail account.
      </Text>
      <Button
        as="a"
        leftIcon={<Icon as={GoogleIcon} />}
        mt="4"
        width="328px"
        height="64px"
        variant="outline"
        borderRadius="lg"
        href="https://autho.voosh.in/authorize"
        target="_blank"
        rel="noopener noreferrer"
      >
        Authorize Gmail
      </Button>
    </Box>
  );
}
