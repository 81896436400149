import { Progress } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import MailReadError from '../components/MailReadError';
import MailReadInProgress from '../components/MailReadInProgress';
import { useAuth } from '../context/AuthContext';
import { getMe } from '../services/api';
import Dashboard from './Dashboard';
import GmailAuthoNeeded from './GmailAuthoNeeded';
import Welcome from './Welcome';
import firebase from "firebase/app";
import "firebase/messaging";

export default function Financial() {
  
  function x(){
    if (!firebase.apps.length) {
      firebase.initializeApp({
          apiKey: "AIzaSyDxSib7Se8efX5Eew8_JoZEC6_3iGi-zwo",
          authDomain: "nimble-blender.firebaseapp.com",
          projectId: "nimble-blender",
          storageBucket: "nimble-blender.appspot.com",
          messagingSenderId: "851261151067",
          appId: "851261151067:web:1bc29f07f43976b486ec7e",
      });
  }
  const messaging = firebase.messaging();

  messaging.onMessage((payload) => {
    console.log('Message received. ', payload);
    // ...
  });
  }
  x();

  const { getToken, currentUser } = useAuth();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getToken().then(token => {
      console.log(token);
      getMe(token)
        .then(response => {
          const { data } = response;
          setUser({ ...data });
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    });
  }, [getToken]);

  if (isLoading)
    return <Progress size="sm" isIndeterminate colorScheme="blue" />;

  const { isMailAuthorized, mailReadStatus } = user;

  if (!isMailAuthorized)
    return (
      <Welcome currentUser={currentUser}>
        <GmailAuthoNeeded />
      </Welcome>
    );

  if (mailReadStatus === 'ERROR')
    return (
      <Welcome currentUser={currentUser}>
        <MailReadError />
      </Welcome>
    );

  if (mailReadStatus === 'TENTATIVE')
    return (
      <Welcome currentUser={currentUser}>
        <MailReadInProgress />
      </Welcome>
    );

  return <Dashboard />;
}
