const GoogleIcon = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 12.2667C24 11.28 23.9183 10.56 23.7415 9.81335H12.2449V14.2666H18.9931C18.8571 15.3733 18.1225 17.04 16.4898 18.1599L16.4669 18.309L20.1019 21.0687L20.3537 21.0934C22.6666 19 24 15.92 24 12.2667Z"
      fill="#4285F4"
    />
    <path
      d="M12.2449 23.9999C15.551 23.9999 18.3264 22.9332 20.3537 21.0933L16.4898 18.1599C15.4558 18.8666 14.068 19.3599 12.2449 19.3599C9.00682 19.3599 6.25854 17.2666 5.27886 14.3733L5.13526 14.3852L1.35552 17.2519L1.30609 17.3866C3.31967 21.3065 7.45574 23.9999 12.2449 23.9999Z"
      fill="#34A853"
    />
    <path
      d="M5.27886 14.3732C5.02036 13.6266 4.87076 12.8265 4.87076 11.9999C4.87076 11.1732 5.02036 10.3732 5.26526 9.62655L5.25841 9.46753L1.43131 6.55481L1.30609 6.61318C0.476196 8.23987 0 10.0666 0 11.9999C0 13.9332 0.476196 15.7598 1.30609 17.3865L5.27886 14.3732Z"
      fill="#FBBC05"
    />
    <path
      d="M12.2452 4.63997C14.5444 4.63997 16.0954 5.6133 16.9798 6.42669L20.4356 3.12C18.3132 1.18667 15.5512 0 12.2452 0C7.45601 0 3.31995 2.69331 1.30637 6.61328L5.26553 9.62666C6.25882 6.73333 9.00709 4.63997 12.2452 4.63997Z"
      fill="#EB4335"
    />
  </svg>
);

export default GoogleIcon;
