import { Box, Stack, Text, VStack } from '@chakra-ui/layout';
import { Button, Icon, Image, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import GoogleIcon from '../components/GoogleIcon';
import { useAuth } from '../context/AuthContext';
import vooshLogo from '../static/vooshLogo.png';
import mixpanel from '../services/mixpanel';


export default function GSignIn() {
  const history = useHistory();

  const { signInWithGoogle } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const googleSignInWithPopUp = () => {
    setIsLoading(true);
    signInWithGoogle()
      .then((data) => {
        setTimeout(() => {
          history.push('/');
        }, 600);
        return mixpanel.track('Sign In',{'email':data.user.email});
      }).then((event) => console.log(event))
      .catch(err => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <VStack>
      <Box marginTop="40px">
        <Image src={vooshLogo} height="50px" width="100px" alt="Logo" />
      </Box>
      <Box>
        <Text
          fontSize="28px"
          fontWeight="700"
          color="#3F414E"
          textAlign="center"
        >
          Welcome!
        </Text>
        <Text
          maxW="258px"
          fontSize="16px"
          fontWeight="300"
          textAlign="center"
          color="#A1A4B2"
        >
          Please enter your mail id which has payment mails from Swiggy and
          Zomato.
        </Text>
      </Box>

      <Box>
        <Button
          isLoading={isLoading}
          leftIcon={<Icon as={GoogleIcon} />}
          width="328px"
          height="64px"
          variant="outline"
          borderRadius="lg"
          onClick={() => googleSignInWithPopUp()}
        >
          CONTINUE WITH GOOGLE
        </Button>
        <Text
          mt="28px"
          maxW="328px"
          fontSize="14px"
          fontWeight="700"
          textAlign="center"
          color="#A1A4B2"
        >
          DONT HAVE GMAIL? ENTER YOUR MAIL ID AND CONTACT SUPPORT
        </Text>
      </Box>

      <Box as="form">
        <Stack spacing={4} minWidth="328px">
          <Input placeholder="email address" variant="filled" size="lg" />
          <Input placeholder="phone number" variant="filled" size="lg" />
        </Stack>
        <Box mt="4">
          <Button
            width="328px"
            height="64px"
            borderRadius="lg"
            color="white"
            bgGradient="linear(to-r, #FF5920, #FFA20F)"
            _hover={{ bg: '#FF5920' }}
            _active={{
              bg: '#FF5920',
            }}
            _focus={{
              borderColor: '#FF5920',
            }}
          >
            Contact Support
          </Button>
        </Box>
      </Box>
    </VStack>
  );
}
