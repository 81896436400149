import { Box, Flex, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import stringToColor from 'string-to-color';
import CircleIcon from './CircleIcon';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);

const mapData = item => {
  const { name, ...other } = item;
  const color = stringToColor(name);
  return {
    name: name,
    value: item[Object.keys(other)[0]],
    color,
  };
};

export default function DataPieChart({ items }) {
  const data = items.map(item => mapData(item));
  return (
    <Box>
      ...
      <ResponsiveContainer aspect={2}>
        <PieChart>
          <Pie
            data={data}
            innerRadius={40}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Table>
        <Tbody>
          {data.map(item => (
            <Tr key={item.name}>
              <Td p="0">
                <Flex>
                  <CircleIcon color={item.color} mt="0.5" mr="2" />
                  <Text fontSize="16px" fontWeight="400" color="#262D30">
                    {item.name}
                  </Text>
                </Flex>
              </Td>
              <Td isNumeric>
                <Text fontSize="16px" fontWeight="600" color="#262D30">
                  {currencyFormatter.format(item.value)}
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
