import firebase from "firebase/app";
import { addPushToken } from './api';
import localforage from "localforage";
import "firebase/messaging";

export async function generateToken(userToken) {
    console.log({ userToken });
    //   if firebase is not initialized,Initialize it
    if (!firebase.apps.length) {
        firebase.initializeApp({
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
        });
    }
    const messaging = firebase.messaging();
    try {
        let token = await localforage.getItem('push_token');
        console.log(token);
        if (token) return;
        console.log("Generating Push token");
        token = await messaging.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY });
        token && await localforage.setItem('push_token', token);
        token && await addPushToken(userToken, token);

    } catch (error) {
        console.log('error in generating push token', error);
    }
}