import { Box, Image, Text } from '@chakra-ui/react';
import mailErrorImage from '../static/mailError.png';

export default function MailReadError() {
  return (
    <Box p="4">
      <Text fontSize="30px" fontWeight="200" color="#41474A" textAlign="center">
        {`didn't find your payment mails`}
      </Text>
      <Text fontSize="16px" fontWeight="400" color="#C0C2D1" textAlign="center">
        Please login through your mail which has payment mails.
      </Text>
      <Box mt="4">
        <Image mx="auto" src={mailErrorImage} alt="inprogress" />
      </Box>
    </Box>
  );
}
