import { Box, Image, Text } from '@chakra-ui/react';
import mailReadInProgressImage from '../static/mailInProgress.png';

export default function MailReadInProgress() {
  return (
    <Box p="4">
      <Text fontSize="30px" fontWeight="200" color="#41474A" textAlign="center">
        welcome to Voosh
      </Text>
      <Text fontSize="16px" fontWeight="400" color="#C0C2D1" textAlign="center">
        Please be patient. Your account statistics are getting ready.
      </Text>
      <Box mt="4">
        <Image mx="auto" src={mailReadInProgressImage} alt="inprogress" />
      </Box>
    </Box>
  );
}
