import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@chakra-ui/layout';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Progress,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  IconButton,
  DrawerCloseButton
} from '@chakra-ui/react';
import { BellIcon } from '@chakra-ui/icons'

import { format, subMonths } from 'date-fns';
import produce from 'immer';
import { useMediaQuery } from 'react-responsive'
import { useEffect, useReducer, useState } from 'react';
import MailReadInProgress from '../components/MailReadInProgress';
import { useAuth } from '../context/AuthContext';
import { getFinancialOverview, getNotifications } from '../services/api';
import ChannelBreakdown from './ChannelBreakdown';
import OverviewGrid from './OverviewGrid';
import Welcome from './Welcome';
import mixpanel from '../services/mixpanel';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
    border: '0',
    // borderBottom: '1px solid grey'
  },

  pos: {
    marginBottom: 3,
  },
});

const pastMonths = () => {
  const date = new Date();
  const lastDate = subMonths(date, 1);
  const lastLastDate = subMonths(date, 2);
  const months = [
    { value: date.getMonth() + 1, name: format(date, 'MMMM') },
    { value: lastDate.getMonth() + 1, name: format(lastDate, 'MMMM') },
    { value: lastLastDate.getMonth() + 1, name: format(lastLastDate, 'MMMM') },
  ];
  return months;
};

const pastMonthObject = () => {
  const months = pastMonths();
  const reducer = (acc, item) => {
    acc[item.value] = { ...item };
    return acc;
  };
  const monthsObject = months.reduce(reducer, {});
  return monthsObject;
};

const getInitialState = () => {
  return {
    isLoading: true,
    data: null,
  };
};
// @@@@@
const dataReducer = produce((draft, action) => {
  switch (action.type) {
    case 'ADD_DATA': {
      const { data } = action;
      draft.data = data;
      draft.isLoading = false;
      break;
    }
    case 'ADD_NOTIFICATION': {
      const { notifications } = action;
      draft.notifications = notifications;
      break;
    }
    default:
      break;
  }
});

const getInitialMonth = () => {
  return pastMonths()[0];
};

export default function Dashboard() {
  // function notificationOnOpen() {
  //   console.log(data)
  // }
  const { getToken, currentUser } = useAuth();
  const classes = useStyles();


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const [month, setMonth] = useState(() => getInitialMonth());
  const [isUpdating, setIsUpdating] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [data, dispatch] = useReducer(dataReducer, getInitialState());

  // used by the drawer
  const {
    isOpen: isTimelineOpen,
    onOpen: timelineOnOpen,
    onClose: timelineOnClose,
  } = useDisclosure();
  const {
    isOpen: isNotificationPageOpen,
    onOpen: notificationPageOnOpen,
    onClose: notificationPageOnClose,
  } = useDisclosure();

  useEffect(() => {
    async function fetchInitialData() {
      const token = await getToken();
      const result = await getNotifications(token);
      let notifications = result.data.data ? result.data.data : [];
      console.log('RESPONSE->', notifications);

      dispatch({
        type: 'ADD_NOTIFICATION',
        notifications
      });

      const response = await getFinancialOverview(token);
      console.log('RESPONSE->', response);
      const { data } = response;

      dispatch({
        type: 'ADD_DATA',
        data,
      });
    }
    fetchInitialData();
  }, [getToken]);

  const logoutHandler = () => {
    const { email } = currentUser;
    const event = mixpanel.track('Sign Out', { email });
    console.log(event);
  }

  const dateChangeHandler = async value => {
    const month = pastMonthObject()[value];
    const token = await getToken();
    setIsUpdating(true);
    try {
      const response = await getFinancialOverview(token, month.value);
      console.log('FILTERED RESPONSE->', response);
      if (response.status === 404) {
        setIsNotFound(true);
        return;
      }
      const { data } = response;
      dispatch({
        type: 'ADD_DATA',
        data,
      });
    } catch (err) {
      const { response } = err;
      const { status } = response;
      if (status === 404) setIsNotFound(true);
    } finally {
      setMonth({ ...month });
      setIsUpdating(false);
    }
  };

  if (data.isLoading)
    return <Progress size="md" isIndeterminate colorScheme="blue" />;

  if (isNotFound) {
    return (
      <Welcome currentUser={currentUser}>
        <MailReadInProgress />
      </Welcome>
    );
  }

  return (
    <Box>
      {isUpdating ? (
        <Progress size="md" isIndeterminate colorScheme="red" />
      ) : null}
      <Box
        pb="4"
        bgGradient="linear(to-r, #FE645A, #FFB039)"
        borderBottomRadius="24px"
      >
        <Flex>
          <Spacer />
          <Button
            mt="6"
            mr="4"
            size="sm"
            color="white"
            borderRadius="lg"
            variant="outline"
            onClick={timelineOnOpen}
          >
            {month.name}
          </Button>
          <IconButton
            variant="outline"
            aria-label="Send email"
            mt="6"
            mr="4"
            size="sm"
            color="white"
            borderRadius="lg"
            icon={< BellIcon />}
            onClick={notificationPageOnOpen}
          />
          <Button
            mt="6"
            mr="4"
            size="sm"
            color="white"
            borderRadius="lg"
            variant="outline"
            onClick={logoutHandler}
          >
            Log Out
          </Button>
        </Flex>
        <OverviewGrid item={data.data} />
      </Box>
      {isDesktopOrLaptop && <Box p="4" style={{ maxWidth: "700px", margin: "2em 30% 1em 20%" }} >
        <Text fontSize="20px" color="#3F414E" fontWeight="700">
          Channel financial breakdown
        </Text>
        <ChannelBreakdown breakdown={data.data} />
      </Box>}

      {!isDesktopOrLaptop && <Box p="4" >
        <Text fontSize="20px" color="#3F414E" fontWeight="700">
          Channel financial breakdown
        </Text>
        <ChannelBreakdown breakdown={data.data} />
      </Box>}
      <Drawer
        placement={'right'}
        onClose={notificationPageOnClose}
        isOpen={isNotificationPageOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            color="white"
            borderRadius="lg"
          />
          <DrawerHeader
            bgGradient="linear(to-r, #FE645A, #FFB039)"
          >
            <Text fontSize="20px" fontWeight="700" color="white"
            >
              notifications
            </Text>
          </DrawerHeader>
          <DrawerBody>
            {!data.notifications[0] && <Typography variant="h6" component="h6" >
              No notification for you.
            </Typography>}
            {data.notifications[0] && data.notifications.map((value, index) => {
              return (<span key={index}><Card className={classes.root} elevation={0} >
                <CardContent>
                  <Typography variant="h6" component="h6">
                    {value.title}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {value.message}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    2 days ago
                  </Typography>
                </CardContent>
                {value.click_action && <CardActions>
                  <a href={value.click_action.toString()}><Button size="small">{"Click me"}</Button></a>
                </CardActions>}
              </Card>
                <Divider />
              </span>)
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {!isDesktopOrLaptop && <Drawer
        placement={'bottom'}
        onClose={timelineOnClose}
        isOpen={isTimelineOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Text fontSize="20px" fontWeight="700">
              Choose timeline
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <RadioGroup onChange={dateChangeHandler} value={month.value}>
              <Stack>
                {pastMonths().map(item => (
                  <Radio key={item.name} value={item.value} colorScheme="red">
                    {item.name}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      }
      {isDesktopOrLaptop && <Drawer
        placement={'right'}
        onClose={timelineOnClose}
        isOpen={isTimelineOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Text fontSize="20px" fontWeight="700">
              Choose timeline
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <RadioGroup onChange={dateChangeHandler} value={month.value}>
              <Stack>
                {pastMonths().map(item => (
                  <Radio key={item.name} value={item.value} colorScheme="red">
                    {item.name}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      }
    </Box>
  );
}
