import { ChakraProvider, theme } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Home from './pages/Home';
import SignIn from './pages/SignIn';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Switch>
            <Route path="/" exact={true}>
              <Home />
            </Route>
            <Route path="/signin" exact={true}>
              <SignIn />
            </Route>
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
