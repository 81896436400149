import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';

const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const currencyFormatter = new Intl.NumberFormat('en-IN', currencyOptions);

export default function ChannelOverviewGrid({ item }) {
  const totalSales = currencyFormatter.format(item.totalSales);
  const netPayout = currencyFormatter.format(item.netPayout);
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={2} py="2" mt="2">
      <GridItem colSpan={1} bg="#F5F5F9" borderRadius="lg" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Delivered Order(s)
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#27AE60">
          {item.deliveredOrders}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#A1A4B2">
          Total Orders Successfully Delivered by Swiggy
        </Text>
        <Flex justifyContent="flex-end">
          {/* <Button size="xs" variant="ghost">
            know more
          </Button> */}
        </Flex>
      </GridItem>
      <GridItem colSpan={1} bg="#F5F5F9" borderRadius="lg" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Cancelled Orders
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#EB5757">
          {item.merchantCancelledOrders}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#A1A4B2">
          Total Orders Cancelled by Merchant
        </Text>
        <Flex justifyContent="flex-end">
          {/* <Button size="xs" variant="ghost">
            know more
          </Button> */}
        </Flex>
      </GridItem>
      <GridItem colSpan={1} bg="#F5F5F9" borderRadius="lg" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Total Sales
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#262D30">
          {totalSales}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#A1A4B2">
          Inclusive of GST liability of Merchant
        </Text>
        {/* <Button size="xs" variant="ghost" float="right">
          know more
        </Button> */}
      </GridItem>
      <GridItem colSpan={1} bg="#F5F5F9" borderRadius="lg" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Net Payout
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#219653">
          {netPayout}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#A1A4B2">
          Inclusive of GST liability of Merchant
        </Text>
        {/* <Button size="xs" variant="ghost" float="right">
          know more
        </Button> */}
      </GridItem>
    </Grid>
  );
}
