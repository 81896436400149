import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive'


const currencyOptions = {
  style: 'currency',
  currency: 'INR',
};
const numberFormat = new Intl.NumberFormat('en-IN', currencyOptions);

export default function OverviewGrid({ item }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const totalSales = numberFormat.format(item.totalSales);
  const deductions = numberFormat.format(item.totalDeductions);
  const totalPayout = numberFormat.format(item.totalPayout);
  return (<span>
    {isDesktopOrLaptop && <Grid templateColumns="repeat(2, 1fr)" gap={2} px="4" mt="4" style={{ maxWidth: "700px", margin: "2em 30% 1em 20%" }}>
      <GridItem colSpan={1} bg="white" borderRadius="24px" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Total Sales
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#262D30">
          {totalSales}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#C0C2D1">
          Total sales inclusive of taxes
        </Text>
        <Flex>
          {/* <Button size="xs" variant="ghost" float="right">
           know more
         </Button> */}
        </Flex>
      </GridItem>
      <GridItem colSpan={1} bg="white" borderRadius="24px" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Deductions
        </Text>
        <Text fontSize="20px" fontWeight="600" color="red">
          {deductions}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#C0C2D1">
          Inclusive of comissions
        </Text>
        {/* <Button size="xs" variant="ghost" float="right">
         know more
       </Button> */}
      </GridItem>
      <GridItem colSpan={2} bg="white" borderRadius="24px" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Total Payout
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#219653">
          {totalPayout}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#C0C2D1">
          Calculated for total sales after deductions
        </Text>
        {/* <Button size="xs" variant="ghost" float="right">
         know more
       </Button> */}
      </GridItem>
    </Grid>}
    {!isDesktopOrLaptop && <Grid templateColumns="repeat(2, 1fr)" gap={2} px="4" mt="4">
      <GridItem colSpan={1} bg="white" borderRadius="24px" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Total Sales
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#262D30">
          {totalSales}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#C0C2D1">
          Total sales inclusive of taxes
        </Text>
        <Flex>
          {/* <Button size="xs" variant="ghost" float="right">
           know more
         </Button> */}
        </Flex>
      </GridItem>
      <GridItem colSpan={1} bg="white" borderRadius="24px" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Deductions
        </Text>
        <Text fontSize="20px" fontWeight="600" color="red">
          {deductions}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#C0C2D1">
          Inclusive of comissions
        </Text>
        {/* <Button size="xs" variant="ghost" float="right">
         know more
       </Button> */}
      </GridItem>
      <GridItem colSpan={2} bg="white" borderRadius="24px" p="4">
        <Text fontSize="14px" color="#919398" fontWeight="700">
          Total Payout
        </Text>
        <Text fontSize="20px" fontWeight="600" color="#219653">
          {totalPayout}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#C0C2D1">
          Calculated for total sales after deductions
        </Text>
        {/* <Button size="xs" variant="ghost" float="right">
         know more
       </Button> */}
      </GridItem>
    </Grid>}
  </span>);
}
