import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import ChannelOverviewGrid from './ChannelOverviewGrid';
import DataPieChart from './DataPieChart';

export default function ChannelBreakdown({ breakdown }) {
  const { swiggy, zomato } = breakdown;

  return (
    <Tabs my="4" colorScheme="orange" size="sm">
      <TabList>
        <Tab fontWeight="700">SWIGGY</Tab>
        <Tab fontWeight="700">ZOMATO</Tab>
      </TabList>
      <TabPanels>
        <TabPanel p="0">
          <ChannelOverviewGrid item={swiggy} />
          <Text fontSize="14px" fontWeight="700" color="#262D30">
            Deductions
          </Text>
          <DataPieChart items={swiggy.deductions} />
        </TabPanel>
        <TabPanel p="0">
          <ChannelOverviewGrid item={zomato} />
          <Text fontSize="14px" fontWeight="700" color="#262D30">
            Deductions
          </Text>
          <DataPieChart items={zomato.deductions} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
