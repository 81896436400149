import { Progress } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Financial from '../components/Financial';
import { useAuth } from '../context/AuthContext';
import { generateToken } from '../services/generateToken';

export default function Home() {
  const history = useHistory();
  const { currentUser, isAuthLoading, getToken } = useAuth();

  useEffect(() => {
    !isAuthLoading && !currentUser && history.push('/signin');
  }, [isAuthLoading, currentUser, history]);

  useEffect(() => {
    async function temp() {
      const userToken = await getToken();
      console.log(userToken);
      userToken && await generateToken(userToken);
    }
    currentUser && temp();
  }, [currentUser])

  if (currentUser) {

    return <Financial />;
  }
  return <Progress size="sm" isIndeterminate colorScheme="orange" />;
}


